import React from 'react';
// import { FaInstagram /* FaWallet */ } from "react-icons/fa";

import { Box } from '@chakra-ui/layout';
import { Button as ChakraButton } from '@chakra-ui/react';
import { Result, Typography } from 'antd';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { Link } from 'react-router-dom';
import { FaTelegram } from 'react-icons/fa';

export const SuccessPage = () => {
  React.useEffect(() => {
    const firebaseConfig = {
      apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
      authDomain: 'missis-laser.firebaseapp.com',
      projectId: 'missis-laser',
      storageBucket: 'missis-laser.appspot.com',
      messagingSenderId: '514174044584',
      appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
      measurementId: 'G-QTNN569ZLV',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    logEvent(analytics, 'rozygryshgeo2_success_appointment');
  }, []);

  return (
    <Box height='100vh' display='flex' alignItems='center' justifyContent='center'>
      <Result
        status='success'
        title='Успешно'
        extra={
          <div style={{ marginTop: -16 }}>
            <Typography.Title level={5} style={{ textAlign: 'center', fontSize: 12, lineHeight: '160%' }}>
              Вы успешно зарегистрировались на розыгрыш!
              <br />И чтобы его не пропустить перейдите по ссылке в наш телеграм канал, подпишитесь на него и ожидайте
              розыгрыша!
            </Typography.Title>
            <a href={`https://t.me/+ubeQcEg_lRo0YWUy`}>
              <ChakraButton size='sm' colorScheme='telegram' leftIcon={<FaTelegram />}>
                telegram
              </ChakraButton>
            </a>
          </div>
        }
      />
    </Box>
  );
};
